import { saveAs } from "file-saver";
import { MDBCol, MDBIcon, MDBRow, MDBSpinner } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import * as uniqId from "uniqid";

import axiosDelete from "../../helpers/axiosDelete";
import axiosGet from "../../helpers/axiosGet";

import axiosPut from "../../helpers/axiosPut";
import Button from "../Button/Button";
import CardWrapper from "../Card/CardWrapper";

const StyledInput = styled.div`
  /* background: #ffffff; */
  background: ${({ isExist }) => (isExist ? "#f5f5f5" : "#ffffff")};
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledAbsoluteInput = styled.div`
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;

const StyledButton = styled.button`
  font-size: 14px;
  font-weight: 700;
  border-radius: 30px;
  color: white;
  min-height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.isDelete ? "#F93154" : props.isDownload ? "#ADDFFF" : "#43BA94"};
  border: none;
  pointer-events: ${(props) => props.isUpload && "none"};
  min-width: ${(props) => (props.isDelete ? "40px" : "90px")};

  :disabled {
    background: #757575;
  }

  i {
    pointer-events: none;
  }
`;

const FileFormKalog = ({
  formId,
  handleNext,
  data,
  docs,
  docsOther,
  disableNext,
  fields,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setError,
    clearErrors,
  } = useForm({ shouldUnregister: true });

  const onSubmit = (data) => {
    handleNext(data);
  };

  return (
    <CardWrapper>
      <form onSubmit={handleSubmit(onSubmit)} id={formId}>
        <MDBRow className="g-4">
          {fields.map((field) => (
            <MDBCol size="12" key={field.name}>
              <Field
                field={field}
                rhf={{
                  register: register,
                  errors: errors,
                  watch: watch,
                  setError: setError,
                  clearErrors: clearErrors,
                }}
                data={data}
                docs={docs}
                disableNext={disableNext}
              />
            </MDBCol>
          ))}

          <FieldArray
            rhf={{
              register: register,
              errors: errors,
              setError: setError,
              clearErrors: clearErrors,
            }}
            data={data}
            disableNext={disableNext}
            docs={docsOther}
          />
        </MDBRow>
      </form>
    </CardWrapper>
  );
};

const Field = ({ field, rhf, data, docs, disableNext }) => {
  const { name, label } = field;
  const { register, errors, watch, setError, clearErrors } = rhf;
  const { uniqId } = data;

  const value = watch(name);

  let doc = {};
  docs.forEach((element) => {
    if (element.type === name) {
      doc.isExist = true;
      doc.fileId = element.fileId;
      //   doc.claimCargoId = element.claimCargoId;
      doc.filename = element.filename;
      doc.description = element.description;
      doc.revisionNotes = element.revisionNotes;
    }
  });

  const [downloadLoading, setDownloadLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  const handleLampirkan = (e) => {
    const file = e.target.files[0];
    clearErrors(name);

    if (file) {
      //  max size DOC_OPENING / upload video adalah 20MB
      if (name === "DOC_OPENING" && file.size > 20_000_000) {
        setError(
          name,
          {
            type: "manual",
            message: "File too big, max 20MB",
          },
          {
            shouldFocus: true,
          }
        );
      }
      //  selain itu, max size adalah 5MB
      else if (name !== "DOC_OPENING" && file.size > 5_000_000) {
        setError(
          name,
          {
            type: "manual",
            message: "File too big, max 5MB",
          },
          {
            shouldFocus: true,
          }
        );
      } else {
        setUploadLoading(true);
        disableNext(true);

        const fd = new FormData();
        fd.append("file", file);
        fd.append("type", name);
        fd.append("description", label);

        axiosPut({
          url: `/claim-kalog/upload/${uniqId}`,
          data: fd,
          callback: (res) => {
            // cek tipe dokumen, apakah ada yang sama atau tidak
            const index = docs.findIndex((doc) => doc.type === name);

            // jika tidak, push dokumen baru
            if (index === -1) {
              docs.push(res.data);
            } else {
              // jika ada yang sama, replace dokumen yang lama
              docs[index] = res.data;
            }

            setUploadLoading(false);
            disableNext(false);
          },
          errorCallback: (err) => {
            setUploadLoading(false);
            disableNext(false);
            console.log(err);
          },
        });
      }
    }
  };

  const handleDownload = (fileId, filename) => {
    setDownloadLoading(true);

    axiosGet({
      url: `/claim-kalog/download/fileId/${fileId}`,
      responseType: "blob",
      callback: () => {
        setDownloadLoading(false);
      },
      errorCallback: (res) => {
        setDownloadLoading(false);

        let file = new File([res], filename);
        saveAs(file);
      },
    });
  };

  return (
    <>
      <label htmlFor={name}>{label}</label>

      <input
        type="file"
        className="form-control form-control-lg"
        id={name}
        name={name}
        {...register(name, {
          onChange: handleLampirkan,
        })}
        style={{ display: "none" }}
        accept={
          ".jpg, .jpeg, .png, .pdf, .mp4, .3gp, .webm, .mkv, .avi, .mpeg, .m4v, .HEIC, .heic"
        }
      />

      <StyledInput isExist={doc.isExist}>
        <span>
          {value?.length
            ? value[0].name
            : doc.isExist
            ? doc.filename
            : "Pilih file"}
        </span>

        <div>
          {doc.isExist && (
            <StyledButton
              type="button"
              disabled={downloadLoading}
              onClick={() => handleDownload(doc.fileId, doc.filename)}
              isDownload
            >
              {downloadLoading ? (
                <MDBSpinner role="status" size="sm">
                  <span className="visually-hidden">Loading...</span>
                </MDBSpinner>
              ) : (
                "Download"
              )}
            </StyledButton>
          )}

          {
            <label htmlFor={name}>
              <div style={{ cursor: "pointer" }}>
                <StyledButton type="button" disabled={uploadLoading} isUpload>
                  {uploadLoading ? (
                    <MDBSpinner role="status" size="sm">
                      <span className="visually-hidden">Loading...</span>
                    </MDBSpinner>
                  ) : (
                    "Lampirkan"
                  )}
                </StyledButton>
              </div>
            </label>
          }
        </div>
      </StyledInput>

      {doc.revisionNotes && (
        <div
          style={{
            fontSize: "12px",
            marginTop: "10px",
            fontWeight: "bold",
            color: "red",
            fontStyle: "italic",
          }}
        >
          Notes: {doc.revisionNotes}
        </div>
      )}
      {name === "DOC_OPENING" ? (
        <p className="mb-0 mt-1 text-danger small">*Maximum ukuran file 20MB</p>
      ) : (
        <p className="mb-0 mt-1 text-danger small">*Maximum ukuran file 5MB</p>
      )}

      {errors[name] && (
        <p className="mb-0 mt-1 text-danger small">*{errors[name].message}</p>
      )}
    </>
  );
};

const FieldArray = ({ rhf, data, docs, disableNext }) => {
  const [fields, setFields] = useState(docs);
  const [addFieldDisabled, setAddFieldDisabled] = useState(false);

  useEffect(() => {
    if (docs) {
      setFields(docs);
    }
  }, [docs]);

  const addField = () => {
    const newField = {
      id: uniqId(),
      description: "",
    };

    setFields([...fields, newField]);
  };

  const removeField = (id) => {
    const filteredDocs = fields.filter((field) => field.id !== id);
    setFields(filteredDocs);
  };

  const changeField = (data, id) => {
    const newFields = [...fields];

    const index = newFields.findIndex((field) => field.id === id);

    if (index === -1) {
      // do nothing
    } else {
      // change object value with new data
      newFields[index] = data;
      setFields(newFields);
    }
  };

  const addDescription = (desc, id) => {
    const newFields = [...fields];

    const newFieldsWithDesc = newFields.map((field) => {
      return field.id === id
        ? {
            ...field,
            description: desc,
          }
        : field;
    });

    setFields(newFieldsWithDesc);
  };

  const disableAddField = (value) => {
    setAddFieldDisabled(value);
  };

  return (
    <>
      {fields?.map((field, index) => (
        <MDBCol size="12" key={field.id}>
          <SingleFieldArray
            index={index}
            field={field}
            rhf={rhf}
            data={data}
            docs={docs}
            disableNext={disableNext}
            handleField={{
              changeField: changeField,
              removeField: removeField,
              addDescription: addDescription,
              disableAddField: disableAddField,
            }}
          />
        </MDBCol>
      ))}

      <MDBCol size="12">
        <Button
          small
          onClick={addField}
          disabled={addFieldDisabled}
          startIcon="plus"
        >
          DOKUMEN LAINNYA
        </Button>
      </MDBCol>
    </>
  );
};

const SingleFieldArray = ({
  index,
  field,
  rhf,
  data,
  disableNext,
  handleField,
}) => {
  const { register, errors, setError, clearErrors } = rhf;
  const { uniqId } = data;
  const { changeField, removeField, addDescription, disableAddField } =
    handleField;

  const name = `DOC_OTHER_${index}`;

  const desc = `${name}_DESC`;
  const descLabel = "Nama Deskripsi";

  const isExist = field.filename ? true : false;

  const [downloadLoading, setDownloadLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleLampirkan = (e) => {
    const file = e.target.files[0];

    clearErrors(name);

    if (file) {
      // validasi file size
      if (file.size > 5_000_000) {
        setError(
          name,
          {
            type: "manual",
            message: "File too big, max 5MB",
          },
          {
            shouldFocus: true,
          }
        );
      } else {
        setUploadLoading(true);
        disableNext(true);
        disableAddField(true);

        const fd = new FormData();
        fd.append("file", file);
        fd.append("type", "DOC_OTHER");
        fd.append("description", field.description);

        axiosPut({
          url: `/claim-kalog/upload/${uniqId}`,
          data: fd,
          callback: (res) => {
            setUploadLoading(false);
            disableNext(false);
            disableAddField(false);
            changeField(res.data, field.id);
          },
          errorCallback: (err) => {
            setUploadLoading(false);
            disableNext(false);
            disableAddField(false);
            console.log(err);
          },
        });
      }
    }
  };

  const handleDownload = (fileId, filename) => {
    setDownloadLoading(true);

    axiosGet({
      url: `/claim-kalog/download/fileId/${fileId}`,
      responseType: "blob",
      callback: () => {
        setDownloadLoading(false);
      },
      errorCallback: (res) => {
        setDownloadLoading(false);

        let file = new File([res], filename);
        saveAs(file);
      },
    });
  };

  const handleDelete = (id, fileId) => {
    // jika file sudah diupload, hapus dari db
    if (isExist) {
      setDeleteLoading(true);
      axiosDelete({
        url: `/claim-kalog/delete/fileId/${fileId}`,
        callback: () => {
          setDeleteLoading(false);
          // kemudian hapus field
          removeField(id);
        },
        errorCallback: (err) => {
          setDeleteLoading(false);
          console.log(err);
        },
      });
    } else {
      // jika belum, hanya hapus field
      removeField(id);
    }
  };

  const handleDescription = (e) => {
    clearErrors(desc);

    addDescription(e.target.value, field.id);
  };

  const handleError = () => {
    if (field.description) {
      clearErrors(desc);
    } else {
      setError(
        desc,
        {
          type: "manual",
          message: `${descLabel} required before upload`,
        },
        {
          shouldFocus: true,
        }
      );
    }
  };

  return (
    <>
      <label>Dokumen Lainnya</label>

      <MDBRow className="g-2">
        <MDBCol md="4">
          <label htmlFor={desc}>{descLabel}</label>

          {isExist ? (
            <StyledInput>{field.description}</StyledInput>
          ) : (
            <>
              <input
                type="text"
                className="form-control form-control-lg"
                id={desc}
                name={desc}
                {...register(desc, {
                  required: `${descLabel} required before upload`,
                  onChange: handleDescription,
                })}
                placeholder={descLabel}
                defaultValue=""
              />

              <p className="mb-0 mt-1 text-danger small">
                *Maximum ukuran file 5MB
              </p>
              {errors[desc] && (
                <p className="mb-0 mt-1 text-danger small">
                  *{errors[desc].message}
                </p>
              )}
            </>
          )}
        </MDBCol>

        <MDBCol md="8">
          <label>File Name</label>

          {isExist ? (
            <StyledInput>
              <span>{field.filename}</span>

              <div>
                <StyledButton
                  type="button"
                  disabled={downloadLoading}
                  onClick={() => handleDownload(field.fileId, field.filename)}
                  isDownload
                >
                  {downloadLoading ? (
                    <MDBSpinner role="status" size="sm">
                      <span className="visually-hidden">Loading...</span>
                    </MDBSpinner>
                  ) : (
                    "Download"
                  )}
                </StyledButton>

                <StyledButton
                  type="button"
                  onClick={() => handleDelete(field.id, field.fileId)}
                  disabled={deleteLoading || uploadLoading}
                  isDelete
                >
                  {deleteLoading ? (
                    <MDBSpinner role="status" size="sm">
                      <span className="visually-hidden">Loading...</span>
                    </MDBSpinner>
                  ) : (
                    <MDBIcon fas icon="trash" />
                  )}
                </StyledButton>
              </div>
            </StyledInput>
          ) : (
            <>
              <StyledWrapper>
                <input
                  type="file"
                  className="form-control form-control-lg"
                  id={name}
                  name={name}
                  {...register(name, {
                    required: field.description && "Please attach a file",
                    onChange: handleLampirkan,
                  })}
                  accept=".jpg, .jpeg, .png, .pdf, .mp4, .3gp, .webm, .mkv, .avi, .mpeg, .m4v, .HEIC, .heic"
                  disabled={!field.description}
                />

                <StyledAbsoluteInput>
                  <span></span>

                  <div>
                    <label htmlFor={name}>
                      <div style={{ cursor: "pointer" }} onClick={handleError}>
                        <StyledButton
                          type="button"
                          disabled={uploadLoading}
                          isUpload
                        >
                          {uploadLoading ? (
                            <MDBSpinner role="status" size="sm">
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </MDBSpinner>
                          ) : (
                            "Lampirkan"
                          )}
                        </StyledButton>
                      </div>
                    </label>

                    <StyledButton
                      type="button"
                      onClick={() => handleDelete(field.id, field.fileId)}
                      disabled={deleteLoading || uploadLoading}
                      isDelete
                    >
                      {deleteLoading ? (
                        <MDBSpinner role="status" size="sm">
                          <span className="visually-hidden">Loading...</span>
                        </MDBSpinner>
                      ) : (
                        <MDBIcon fas icon="trash" />
                      )}
                    </StyledButton>
                  </div>
                </StyledAbsoluteInput>
              </StyledWrapper>

              {errors[name] && (
                <p className="mb-0 mt-1 text-danger small">
                  *{errors[name].message}
                </p>
              )}
            </>
          )}
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default FileFormKalog;
