const initialState = {
  isAllDocsUploaded: false,
};

const kalogReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ALL_DOCS_UPLOADED":
      return {
        ...state,
        isAllDocsUploaded: action.payload,
      };
    case "RESET_KALOG":
      return (state = initialState);
    default:
      return state;
  }
};

export default kalogReducer;
