import "./App.css";
import { Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Banner from "./components/Banner/Banner";
import { MDBContainer } from "mdb-react-ui-kit";
import Footer from "./components/Footer/Footer";
import Riwayat from "./pages/Riwayat/Riwayat";
import Home from "./pages/Home/Home";
import NotFound from "./pages/404/404";
import { useEffect, useState } from "react";
import Klaim from "./pages/Klaim/Klaim";
import KlaimKalog from "./pages/KlaimKalog/Klaim";
import RiwayatDetail from "./pages/Riwayat/RiwayatDetail";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const userCode = sessionStorage.getItem("userCode");
    const signature = sessionStorage.getItem("signature");
    const isKalog = sessionStorage.getItem("isKalog");

    setToken(token);
    setUserCode(userCode);
    setSignature(signature);
    setIsKalog(isKalog);
  }, []);

  const [token, setToken] = useState(null);
  const [userCode, setUserCode] = useState(null);
  const [signature, setSignature] = useState(null);
  const [isKalog, setIsKalog] = useState(null);

  return (
    <div className="App">
      <Navbar />

      <main>
        <ScrollToTop />

        <Routes>
          <Route
            path="/"
            element={
              <MDBContainer>
                <Home />
              </MDBContainer>
            }
          />

          <Route
            path="/riwayat"
            element={
              <MDBContainer>
                {(token && userCode) || (userCode && signature) ? (
                  <>
                    <Banner />
                    <Riwayat />
                  </>
                ) : (
                  <NotFound />
                )}
              </MDBContainer>
            }
          />

          <Route
            path="/riwayat/:id"
            element={
              (token && userCode) || (userCode && signature) ? (
                <RiwayatDetail />
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/klaim/*"
            element={
              <MDBContainer>
                {(token && userCode) || (userCode && signature) ? (
                  <>
                    <Banner />
                    <Klaim />
                  </>
                ) : (
                  <NotFound />
                )}
              </MDBContainer>
            }
          />

          <Route
            path="/klaim-kalog/*"
            element={
              <MDBContainer>
                {userCode && isKalog ? (
                  <>
                    <Banner />
                    <KlaimKalog />
                  </>
                ) : (
                  <NotFound />
                )}
              </MDBContainer>
            }
          />

          <Route
            path="*"
            element={
              <MDBContainer>
                <NotFound />
              </MDBContainer>
            }
          />
        </Routes>
      </main>

      <Footer />
    </div>
  );
}

export default App;
