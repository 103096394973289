import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import moment from "moment";
import styled from "styled-components";

import CardWrapper from "../Card/CardWrapper";
const StyledInsured = styled.div`
  font-weight: 700;
  border-radius: 0.25rem;
  background: #f5f5f7;
  padding: 1rem;
`;

const SingleClaimDetailKalog = ({ detail, claim }) => {
  const insured = detail?.insured || "-";
//   const createdDate = claim?.createdDate
//     ? moment(claim?.createdDate).format("DD MMMM YYYY")
//     : "-";
  const picEmail = claim?.picEmail || "-";
  const picName = claim?.picName || "-";
  const picPhoneNumber = claim?.picPhoneNumber || "-";
  const accidentDate = claim?.accidentDate
    ? moment(claim.accidentDate).format("DD MMMM YYYY")
    : "-";
  const accidentDetail = claim?.accidentDetail || "-";
  return (
    <MDBRow className="g-4">
      <MDBCol size="12">
        <CardWrapper>
          <p className="fw-bolder">Detail</p>

          <MDBRow className="g-2">
            <MDBCol size="12">
              <p className="mb-0 small">Insured</p>

              <StyledInsured>{insured}</StyledInsured>
            </MDBCol>
            <MDBCol md="6">
              <p className="mb-0 small">PIC Nama CS Outlet</p>
              <p className="mb-0 fw-bold">{picName}</p>
            </MDBCol>
            <MDBCol md="6">
              <p className="mb-0 small">PIC Email Pelanggan</p>
              <p className="mb-0 fw-bold">{picEmail}</p>
            </MDBCol>
            <MDBCol md="6">
              <p className="mb-0 small">PIC No Handphone Pelanggan</p>
              <p className="mb-0 fw-bold">{picPhoneNumber}</p>
            </MDBCol>
          </MDBRow>
        </CardWrapper>
      </MDBCol>

      <MDBCol size="12">
        <CardWrapper>
          <p className="fw-bolder">Detail Kronologi</p>

          <MDBRow className="g-2">
            <MDBCol size="12">
              <p className="mb-0 small">Tanggal Kejadian</p>
              <p className="mb-0 fw-bold">{accidentDate}</p>
            </MDBCol>
            <MDBCol size="12">
              <p className="mb-0 small">Detail Kronologi Kejadian</p>
              <p className="mb-0 fw-bold">{accidentDetail}</p>
            </MDBCol>
          </MDBRow>
        </CardWrapper>
      </MDBCol>
    </MDBRow>
  );
};

export default SingleClaimDetailKalog;
