import {
    MDBAccordion,
    MDBAccordionItem,
    MDBCol,
    MDBIcon,
    MDBRow,
    MDBTooltip,
  } from "mdb-react-ui-kit";
  import moment from "moment";
  import styled from "styled-components";
  import { saveAs } from "file-saver";
  
  import { useState } from "react";
  
  import axiosGet from "../../helpers/axiosGet";
  import formatCurrency from "../../helpers/formatCurrency";
  
  import DownloadCard from "../Download/DownloadCard";
  import FileType from "../FileType";
  import CardWrapper from "../Card/CardWrapper";
  import { InvoiceStatusFormat } from "../Formatting/StatusFormat";
  
  const SingleCertificateDetailKalog = ({
    detail,
    claim,
    currentStatus,
    isFromCargo,
    docs,
    statusHistory,
    invoice = null,
  }) => {
    const policyNumber = isFromCargo
      ? detail?.policyNumber || "-"
      : claim?.policyNumber || "-";
    const certificateNumber = isFromCargo
      ? detail?.certificateNumber || "-"
      : detail?.certificateNo || "-";
    const route = isFromCargo ? detail?.blNo : detail?.blNo || "-";
    const departDate = isFromCargo
      ? detail?.estDepartureDate
        ? moment(detail?.estDepartureDate).format("DD MMMM YYYY")
        : ""
      : detail?.departDate
      ? moment(detail?.departDate).format("DD MMMM YYYY")
      : "-";
    const publishDate = isFromCargo
      ? detail?.policyIssueDate
        ? moment(detail?.policyIssueDate).format("DD MMMM YYYY")
        : ""
      : detail?.publishDate
      ? moment(detail?.publishDate).format("DD MMMM YYYY")
      : "-";
    const sumInsured = isFromCargo
      ? detail?.sumInsured
        ? formatCurrency(detail?.sumInsured)
        : "-"
      : claim?.sumInsured
      ? `${claim?.sumInsuredCurrencyCode} ${formatCurrency(claim?.sumInsured)}`
      : "-";
    const netPremium = isFromCargo
      ? detail?.netPremium
        ? formatCurrency(detail?.netPremium)
        : "-"
      : claim?.netPremium
      ? `${claim?.netPremiumCurrencyCode} ${formatCurrency(claim?.netPremium)}`
      : "-";
    const claimAmount = claim?.claimAmount
      ? `${formatCurrency(claim?.claimAmount)}`
      : "-";
  
    const isThereFile = docs?.some(
      (doc) =>
        doc.type === "DOC_LOD_SIGNED" ||
        doc.type === "DOC_LOD" ||
        doc.type === "DOC_PAYMENT"
    );
  
    return (
      <MDBRow className="g-4">
        <MDBCol size="12">
          <CardWrapper>
            <MDBRow className="g-2">
              <MDBCol size="12">
                <p className="mb-0 small">Policy No/Sertifikat No</p>
                <p className="mb-0 fw-bold">
                  {policyNumber}/{certificateNumber}{" "}
                  {invoice !== null && (
                    <>
                      <span
                        className="p-1"
                        style={{
                          border: "1px solid #333333",
                          borderRadius: "3px",
                        }}
                      >
                        <small>{InvoiceStatusFormat(invoice?.status)}</small>
                      </span>{" "}
                      <span>
                        <MDBTooltip
                          tag="a"
                          wrapperProps={{ href: "#" }}
                          title={
                            invoice?.status === 0
                              ? "Certificate is not paid yet, please contact our finance team for futhere payment"
                              : invoice?.status === 100
                              ? "Certificated is already paid"
                              : ""
                          }
                        >
                          <MDBIcon far icon="question-circle" size="lg" />
                        </MDBTooltip>
                      </span>
                    </>
                  )}
                </p>
              </MDBCol>
  
              <MDBCol size="12">
                <hr />
              </MDBCol>
  
              <MDBCol size="12">
                <p className="mb-0 small">No Resi</p>
                <p className="mb-0 fw-bold">{route}</p>
              </MDBCol>
  
              <MDBCol size="12">
                <p className="mb-0 small">Tanggal Keberangkatan</p>
                <p className="mb-0 fw-bold">{departDate}</p>
              </MDBCol>
              <MDBCol size="12">
                <p className="mb-0 small">Tanggal Terbit</p>
                <p className="mb-0 fw-bold">{publishDate}</p>
              </MDBCol>
            </MDBRow>
          </CardWrapper>
        </MDBCol>
  
        <MDBCol size="12">
          <CardWrapper>
            <MDBRow className="g-2">
              <MDBCol size="12">
                <p className="mb-0 small">Total Sum Insured</p>
                <p className="mb-0 fw-bold">{sumInsured}</p>
              </MDBCol>
              <MDBCol size="12">
                <p className="mb-0 small">Premium & Service Fee</p>
                <p className="mb-0 fw-bold">{netPremium}</p>
              </MDBCol>
              <MDBCol size="12">
                <hr />
              </MDBCol>
  
              <MDBCol size="12">
                <p className="mb-0 small">Estimasi Kerugian (IDR)</p>
                <p className="mb-0 fw-bold">{claimAmount}</p>
              </MDBCol>
            </MDBRow>
          </CardWrapper>
        </MDBCol>
  
        {(currentStatus?.statusCode === "310" ||
          currentStatus?.statusCode === "400") && (
          <MDBCol size="12">
            <Status currentStatus={currentStatus} claim={claim} />
          </MDBCol>
        )}
  
        {!isFromCargo && isThereFile && (
          <MDBCol size="12">
            <Files currentStatus={currentStatus} docs={docs} />
          </MDBCol>
        )}
  
        {statusHistory && (
          <MDBCol size="12">
            <StatusHistory statusHistory={statusHistory} />
          </MDBCol>
        )}
      </MDBRow>
    );
  };
  
  const Status = ({ currentStatus, claim }) => {
    const { paymentDate, createdDate, description, statusName, statusCode } =
      currentStatus;
    const { paymentAmount } = claim;
  
    const isPaid = statusCode === "310" ? true : false;
    return (
      <CardWrapper>
        <MDBRow className="g-2">
          <MDBCol size="12">
            <p className="mb-0 fw-bold">{statusName}</p>
          </MDBCol>
  
          <MDBCol size="12">
            <p className="mb-0 small">
              {isPaid ? "Tanggal Pembayaran Klaim" : "Tanggal Penolakan"}
            </p>
            <p className="mb-0 fw-bold">
              {isPaid
                ? `${moment(paymentDate).format("DD MMMM YYYY HH:mm")} WIB`
                : moment(createdDate).format("DD MMMM YYYY")}
            </p>
          </MDBCol>
  
          <MDBCol size="12">
            <p className="mb-0 small">Keterangan</p>
            <p className="mb-0 fw-bold">
              {description ? (description === "null" ? "-" : description) : "-"}
            </p>
          </MDBCol>
  
          {isPaid && (
            <>
              <MDBCol size="12">
                <hr />
              </MDBCol>
  
              <MDBCol size="12">
                <p className="mb-0 small">Jumlah Pembayaran Klaim (IDR)</p>
                <p className="mb-0 fw-bold">
                  {paymentAmount ? formatCurrency(paymentAmount) : "-"}
                </p>
              </MDBCol>
            </>
          )}
        </MDBRow>
      </CardWrapper>
    );
  };
  
  const Files = ({ currentStatus, docs }) => {
    const { statusCode } = currentStatus;
  
    const files = docs
      .filter(
        (doc) =>
          doc.type === "DOC_LOD_SIGNED" ||
          doc.type === "DOC_LOD" ||
          doc.type === "DOC_PAYMENT"
      )
      .map((doc) => {
        let text = "";
  
        FileType.forEach((el) => {
          if (el.type === doc.type) {
            text = el.text;
          }
        });
  
        return {
          ...doc,
          title: text,
        };
      });
  
    const lod = files.filter((file) => file.type === "DOC_LOD");
    const lodSign = files.filter((file) => file.type === "DOC_LOD_SIGNED");
    const payment = files.filter((file) => file.type === "DOC_PAYMENT");
  
    let cards = [];
    if (statusCode === "140") {
      cards.push(...lod);
    } else if (
      statusCode === "150" ||
      statusCode === "300" ||
      statusCode === "400"
    ) {
      cards.push(...lod, ...lodSign);
    } else if (statusCode === "310") {
      cards.push(...lod, ...lodSign, ...payment);
    }
  
    return (
      <MDBRow className="g-4">
        {cards.map((card) => (
          <MDBCol size="12" key={card.title}>
            <File card={card} />
          </MDBCol>
        ))}
      </MDBRow>
    );
  };
  
  const File = ({ card }) => {
    const [loading, setLoading] = useState(false);
  
    const handleDownload = (fileId, filename) => {
      setLoading(true);
  
      axiosGet({
        url: `/claim-kalog/download/fileId/${fileId}`,
        responseType: "blob",
        callback: () => {
          setLoading(false);
        },
        errorCallback: (res) => {
          setLoading(false);
  
          // save file to computer
          saveAs(res, filename);
        },
      });
    };
  
    return (
      <DownloadCard
        title={`Download ${card.title}`}
        handleClick={() => handleDownload(card.fileId, card.filename)}
        loading={loading}
        disabled={loading}
      />
    );
  };
  
  const StyledStatus = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `;
  
  const StyledCircle = styled.div`
    background: ${(props) => (props.index === 0 ? "#006881" : "#646464")};
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin: 0.3rem 0;
  `;
  
  const StyledLine = styled.div`
    height: 55px;
    width: 1px;
    background: #646464;
  `;
  
  const StatusHistory = ({ statusHistory }) => {
    return (
      <MDBAccordion>
        <MDBAccordionItem
          collapseId="status-history"
          headerTitle="Status History"
        >
          <MDBRow>
            {statusHistory.map((x, index) => (
              <MDBCol size="12" key={x.id}>
                <MDBRow className="g-2">
                  <MDBCol size="4" lg="5">
                    <p
                      className={
                        index > 0 ? `mb-0 fw-bold text-muted` : `mb-0 fw-bold`
                      }
                    >
                      {moment(x?.createdDate).format("DD MMMM YYYY HH:mm")} WIB
                    </p>
                  </MDBCol>
  
                  <MDBCol size="1">
                    <StyledStatus>
                      <StyledCircle index={index} />
  
                      {index < statusHistory.length - 1 && <StyledLine />}
                    </StyledStatus>
                  </MDBCol>
  
                  <MDBCol size="7" lg="6">
                    <p
                      className={
                        index > 0 ? `mb-0 fw-bold text-muted` : `mb-0 fw-bold`
                      }
                    >
                      {x.statusName}
                    </p>
                    <p
                      className={
                        index > 0 ? `mb-0 small text-muted` : `mb-0 small`
                      }
                    >
                      {x.description
                        ? x.description === "null"
                          ? ""
                          : x.description
                        : ""}
                    </p>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            ))}
          </MDBRow>
        </MDBAccordionItem>
      </MDBAccordion>
    );
  };
  
  export default SingleCertificateDetailKalog;
  