import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../components/Button/Button";
import SingleCertificateDetailKalog from "../../components/Detail/SingleCertificateDetailKalog";
import SingleClaimDetailKalog from "../../components/Detail/SingleClaimDetailKalog";
import ScrollToHere from "../../components/ScrollToHere";
import Spinner from "../../components/Spinner/Spinner";
import Subheading from "../../components/Subheading/Subheading";
import axiosPost from "../../helpers/axiosPost";

export default function SummaryKalog() {
  const { name } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [detail, setDetail] = useState({});
  const [detailForm, setDetailForm] = useState({});

  const [error, setError] = useState(null);
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);
  const { detailFromCargo } = useSelector((state) => state.detailStore);
  const { dataFormOne } = useSelector((state) => state.klaimStore);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      setDetail(detailFromCargo);
      setDetailForm(dataFormOne);
    }, 2000);
    // axiosGet({
    //   url: `/claim-kalog/uniq-id/${id}`,
    //   callback: (res) => {
    //     setLoading(false);
    //     setDetail(res.data);

    //     const claimCargoStatus = res.data.claimStatus;
    //     setStatusHistory(claimCargoStatus);
    //     setCurrentStatus({
    //       ...claimCargoStatus[0],
    //       paymentDate: res.data.claim.paymentDate,
    //     });

    //     const claimCargoDocument = res.data.claimDocument;
    //     setDocs(claimCargoDocument);
    //   },
    //   errorCallback: (err) => {
    //     setLoading(false);
    //     console.log(err);
    //   },
    // });
  }, [detailFromCargo, dataFormOne]);

  const handleNext = (data) => {
    ScrollToHere();
    setLoading(true);
    setError(null);
    const body = {
      productType: detailFromCargo.productCode,
      companyName: detailFromCargo.companyName,
      policyNumber: detailFromCargo.policyNumber,
      conveyance: detailFromCargo.mainVessel,
      certificateNo: detailFromCargo.certificateNumber,

      typeOfLoss: dataFormOne.typeOfLoss || null,
      accidentDate: dataFormOne.accidentDate,
      accidentDetail: dataFormOne.accidentDetail,
      accidentLocation: dataFormOne.accidentLocation,

      picName: dataFormOne.picName,
      picPhoneNumber: dataFormOne.picPhoneNumber,
      picEmail: dataFormOne.picEmail,
      containerNo: dataFormOne.containerNo,
      claimAmount: dataFormOne.claimAmount,
    };

    // jika belum, bikin claim baru
    axiosPost({
      url: "/claim-kalog/save",
      data: body,
      callback: (res) => {
        setLoading(false);
        const uniqId = res.data.uniqId;
        navigate(`/klaim-kalog/${name}/${uniqId}/next`);
      },
      errorCallback: (err) => {
        setLoading(false);
        setModal(true);
        setError(err);
      },
    });
  };

  return (
    <section className="overflow-hidden">
      {loading ? (
        <Spinner height={70} />
      ) : (
        <>
          {/* <MDBRow className="g-4">
            <MDBCol md="8">
              <ClaimDetailKalog detail={detail} />
            </MDBCol>

            <MDBCol md="4">
              <Subheading>Detail Certificate</Subheading>

              <CertificateDetail detail={detail} kalogMicrosite />
            </MDBCol>
          </MDBRow> */}

          <MDBContainer>
            <MDBRow className="g-4">
              <MDBCol lg="8">
                <SingleClaimDetailKalog
                  detail={detail}
                  docs={[]}
                  claim={detailForm}
                />
              </MDBCol>

              <MDBCol lg="4">
                <Subheading>Detail Certificate</Subheading>
                <SingleCertificateDetailKalog
                  claim={detailForm}
                  detail={detail}
                  isFromCargo
                />
              </MDBCol>
            </MDBRow>

            <div className="d-flex justify-content-between mt-5">
              <Button
                secondary
                startIcon="arrow-left"
                onClick={() => navigate(`/klaim-kalog`)}
              >
                Kembali
              </Button>
              <Button
                // onClick={() => navigate(`/klaim-kalog/${name}/${id}/next`)}
                onClick={handleNext}
              >
                Submit
              </Button>
            </div>
          </MDBContainer>

          <MDBModal show={modal} setShow={setModal} tabIndex="-1">
            <MDBModalDialog centered>
              <MDBModalContent className="bg-danger">
                <MDBModalHeader>
                  <MDBModalTitle className="text-light fw-bold">
                    Error
                  </MDBModalTitle>
                  <MDBBtn
                    className="btn-close btn-close-white"
                    color="none"
                    onClick={toggleModal}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody className="text-light">
                  {error && error.message}
                </MDBModalBody>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        </>
      )}
    </section>
  );
}
