import kalog from "@provis/provis-common-be-module/dist/constants/claim/kalog";
import { MDBCol, MDBContainer, MDBRow, MDBSpinner } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../../../components/Button/Button";
import CertificateDetailKalog from "../../../components/Detail/CertificateDetailKalog";
import ClaimDetailKalog from "../../../components/Detail/ClaimDetailKalog";
import Spinner from "../../../components/Spinner/Spinner";
import StatusBarKalog from "../../../components/StatusBar/StatusBarKalog";
import Subheading from "../../../components/Subheading/Subheading";
import axiosGet from "../../../helpers/axiosGet";
import axiosPut from "../../../helpers/axiosPut";
import moment from "moment";

export default function RiwayatDetailKalog() {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [detail, setDetail] = useState({});

  const [docs, setDocs] = useState([]);
  const [isDocCompleted, setIsDocCompleted] = useState(false);

  const [currentStatus, setCurrentStatus] = useState({});
  const [statusHistory, setStatusHistory] = useState([]);
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    axiosGet({
      url: `/claim-kalog/uniq-id/${id}`,
      callback: (res) => {
        setLoading(false);
        setDetail(res.data);

        const claimCargoStatus = res.data.claimStatus;
        setStatusHistory(claimCargoStatus);
        setCurrentStatus({
          ...claimCargoStatus[0],
          paymentDate: res.data.claim.paymentDate,
        });

        const claimDocument = res.data.claimDocument;
        setDocs(claimDocument);

        const expiredDate = res.data.claim.expiredDate;
        const today = moment();
        const isExpired = moment(today).isAfter(expiredDate);
        setIsExpired(isExpired);

        const filteredKalog = kalog?.LIST_ACTIVE?.filter(
          (type) => type !== "DOC_OTHER"
        );

        const allDocsPresent = filteredKalog.every((type) =>
          claimDocument.some((doc) => doc.type === type)
        );

        if (allDocsPresent) {
          setIsDocCompleted(true);
        }
      },
      errorCallback: (err) => {
        setLoading(false);
        console.log(err);
      },
    });
  }, [id]);

  const handleSubmit = () => {
    setLoadingSubmit(true);
    axiosPut({
      url: `/claim-kalog/status/uniq-id/${id}/submit-document`,
      callback: () => {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      },
      errorCallback: (err) => {
        console.log(err);
      },
    });
  };

  return (
    <section className="overflow-hidden">
      {loading ? (
        <Spinner height={70} />
      ) : (
        <>
          <StatusBarKalog
            id={id}
            detail={detail}
            status={currentStatus}
            claim={detail?.claim}
            isExpired={isExpired}
          />

          <MDBContainer>
            <MDBRow className="g-4">
              <MDBCol lg="8">
                <ClaimDetailKalog
                  detail={detail}
                  docs={docs}
                  claim={detail.claim}
                />
              </MDBCol>

              <MDBCol lg="4">
                <Subheading>Detail Certificate</Subheading>

                <CertificateDetailKalog
                  claim={detail.claim}
                  detail={detail}
                  docs={docs}
                  currentStatus={currentStatus}
                  statusHistory={statusHistory}
                />
              </MDBCol>
            </MDBRow>

            <div className="d-flex justify-content-end mt-5">
              {isDocCompleted &&
                currentStatus?.statusCode === "110" &&
                !isExpired && (
                  <Button onClick={handleSubmit} disabled={loadingSubmit}>
                    {loadingSubmit ? (
                      <MDBSpinner role="status" size="sm">
                        <span className="visually-hidden">Loading...</span>
                      </MDBSpinner>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                )}
            </div>
          </MDBContainer>
        </>
      )}
    </section>
  );
}
