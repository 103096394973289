import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

import axiosGet from "../../helpers/axiosGet";
import { resetKlaim } from "../../store/klaim/actions";

import Button from "../../components/Button/Button";
import CertificateDetail from "../../components/Detail/CertificateDetail";
import DownloadCard from "../../components/Download/DownloadCard";
import FileForm from "../../components/Form/FileForm";
import Spinner from "../../components/Spinner/Spinner";
import Subheading from "../../components/Subheading/Subheading";
import axiosPost from "../../helpers/axiosPost";

const formId = "claim-form-two";

const FormTwo = () => {
  const { name, id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [detail, setDetail] = useState({});
  const [claim, setClaim] = useState({});
  const [currentStatus, setCurrentStatus] = useState({});
  const [statusHistory, setStatusHistory] = useState([]);
  const [docs, setDocs] = useState([]);
  const [docsOther, setDocsOther] = useState([]);
  const [nextDisabled, setNextDisabled] = useState(false);
  const path = `${claim.insuranceCode}`;

  const decode = window.atob(id);

  const [dataInvoice, setDataInvoice] = useState(null);
  const [conversationsLoaded, setConversationsLoaded] = useState(false);

  const { detailFromCargo } = useSelector((state) => state.detailStore);

  useEffect(() => {
    const getAllConversations = async () => {
      await axiosGet({
        url: `/claim-cargo/id/${decode}`,
        callback: (res) => {
          setLoading(false);
          setDetail(res.data);
          setClaim(res.data.claim);
          setConversationsLoaded(true);

          const claimCargoStatus = res.data.claimCargoStatus;
          setStatusHistory(claimCargoStatus);
          setCurrentStatus(claimCargoStatus[0]);

          const claimCargoDocument = res.data.claimCargoDocument;
          setDocs(claimCargoDocument);
          setDocsOther(
            claimCargoDocument.filter((doc) => doc.type === "DOC_OTHER")
          );
        },
        errorCallback: (err) => {
          setLoading(false);
          console.log(err);
        },
      });
    };
    getAllConversations();
  }, [decode, navigate]);

  useEffect(() => {
    if (conversationsLoaded === true) {
      if (detail?.claim?.isClaimNeedPaid) {
        axiosPost({
          // url: `/claim-cargo/invoice/${dataInvoiceFromStore?.refId}`,
          url: `/claim-cargo/invoice/get-by-source`,
          data: {
            source: detailFromCargo?.source,
            refId: detailFromCargo?.refId,
            placingSlipNo: detailFromCargo?.placingSlipNo, //-->UPLOAD-BUNDLE using this
            uniqId: detailFromCargo?.uniqId //-->TEMAS using this
          },
          callback: (res) => {
            setDataInvoice(res.data);
            if (res.data.status === 0) {
              navigate(`/klaim/new/${id}/summary`);
            }
          },
          errorCallback: (res) => {
            console.log(res);
          },
        });
      }
    }
  }, [
    detailFromCargo,
    detail?.claim?.refId,
    conversationsLoaded,
    detail?.claim?.isClaimNeedPaid,
    id,
    navigate,
  ]);

  const disableNext = (value) => {
    setNextDisabled(value);
  };

  const handleNext = (data) => {
    dispatch(resetKlaim());
    navigate(`/riwayat/${id}`);
  };

  return (
    <section className="overflow-hidden">
      {loading ? (
        <Spinner height={70} />
      ) : (
        <>
          <MDBRow className="g-4">
            <MDBCol lg="8">
              <Subheading>Dokumen Klaim</Subheading>

              <div className="mb-4">
                <MDBRow end>
                  <MDBCol lg="8" xl="6">
                    <div className="mb-3">
                      <DownloadCard
                        title="Download Formulir Klaim"
                        href={`/${path}/Formulir_Klaim.docx`}
                        download
                      />
                    </div>

                    <DownloadCard
                      title="Download Surat Tuntutan Pihak Ke-3"
                      href={`/${path}/Surat_Tuntutan_Pihak_Ke-3.docx`}
                      download
                    />
                  </MDBCol>
                </MDBRow>
              </div>

              <FileForm
                formId={formId}
                handleNext={handleNext}
                data={detail}
                docs={docs}
                docsOther={docsOther}
                disableNext={disableNext}
              />
            </MDBCol>

            <MDBCol lg="4">
              <Subheading>Detail Certificate</Subheading>

              <CertificateDetail
                detail={detail}
                claim={claim}
                currentStatus={currentStatus}
                docs={docs}
                statusHistory={statusHistory}
                invoice={dataInvoice}
              />
            </MDBCol>
          </MDBRow>

          <div className="d-flex justify-content-between mt-5">
            <Button
              secondary
              startIcon="arrow-left"
              onClick={() =>
                name === "new" ? navigate(`/klaim`) : navigate(`/riwayat/${id}`)
              }
              disabled={nextDisabled}
            >
              Kembali
            </Button>

            <Button
              endIcon="arrow-right"
              form={formId}
              type="submit"
              disabled={nextDisabled}
            >
              Selanjutnya
            </Button>
          </div>
        </>
      )}
    </section>
  );
};

export default FormTwo;
