import { combineReducers } from "redux"
import riwayatReducer from "./riwayat/riwayatReducer"
import klaimReducer from "./klaim/klaimReducer"
import detailReducer from "./detail/detailReducer"
import kalogReducer from "./kalog/kalogReducer"

const rootReducer = combineReducers({
  riwayatStore: riwayatReducer,
  klaimStore: klaimReducer,
  detailStore: detailReducer,
  kalogStore: kalogReducer,
})

export default rootReducer
