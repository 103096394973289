import { Outlet, Route, Routes } from "react-router-dom";
import Heading from "../../components/Heading/Heading";
import FormOneKalog from "./FormOne";
import SummaryKalog from "./Summary";
import FormUploadDoc from "./FormUploadDoc";
import RiwayatDetailKalog from "./Riwayat/RiwayatDetail";

export default function KlaimKalog() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<FormOneKalog />} />
        <Route path=":name/:id/summary" element={<SummaryKalog />} />
        <Route path=":name/:id/next" element={<FormUploadDoc />} />
        <Route path="/riwayat/:id" element={<RiwayatDetailKalog />} />
      </Route>
    </Routes>
  );
}

function Layout() {
  const currentPath = window.location.pathname;
  return (
    <>
      <Heading>
        {currentPath.includes("check")
          ? "CHECK KLAIM"
          : currentPath.includes("summary")
          ? "DETAIL KLAIM"
          : "FORM KLAIM"}
      </Heading>

      <Outlet />
    </>
  );
}
